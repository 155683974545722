export default (attributeName: string, attributeValue: string) => {
    return {
        // @ts-ignore
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: attributeName,
        ExtensionProperties: [],
        TextValue: attributeValue,
        TextValueTranslations: []
    };
};
